'use client'
import { useContext } from 'react'
import ProfileContext from './ProfileContext'

const useProfile = () => {
  const context = useContext(ProfileContext)
  if (!context) {
    throw Error('No context for Profile, make sure to wrap it in a ProfileProvider')
  }
  return context
}

export default useProfile
