import clsx from 'clsx'
import styles from './Spinner.module.scss'

type SpinnerProps = {
  size?: number
  className?: string
}
const Spinner = ({ size = 50, className }: SpinnerProps) => (
  <div className={clsx(styles.spinnerWrapper, className)}>
    <svg className={styles.spinner} viewBox="0 0 32 32" width={size}>
      <circle cx="16" cy="16" r="14" style={{ opacity: 0.2 }} />
      <circle cx="16" cy="16" r="14" style={{ strokeDasharray: 80, strokeDashoffset: 60 }} />
    </svg>
  </div>
)

export default Spinner
