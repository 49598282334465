import { DependencyList, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ModalContext, ModalType } from '../providers/ModalProvider'

type ShowModal = () => void
type HideModal = () => void

const generateModalKey = (() => {
  let count = 0

  return () => `${++count}`
})()

export const useModal = (
  component: ModalType,
  inputs: DependencyList = []
): [ShowModal, HideModal, boolean] => {
  const key = useMemo(generateModalKey, [])
  const modal = useMemo(() => component, inputs)
  const context = useContext(ModalContext)
  const [isShown, setShown] = useState<boolean>(false)
  const showModal = useCallback(() => setShown(true), [])
  const hideModal = useCallback(() => setShown(false), [])

  useEffect(() => {
    if (isShown) {
      context.showModal(key, modal)
    } else {
      context.hideModal(key)
    }
    return () => context.hideModal(key)
  }, [modal, isShown])

  return [showModal, hideModal, isShown]
}
