type FunctionType = {
  id?: number
  name: string
}

// same order as the SPA DO NOT CHANGE THE ORDER
export const GPI_FUNCTIONS: Record<string, FunctionType> = {
  '101': { name: 'IT' },
  '116': { name: 'IT Security and Risk Management' },
  '122': { name: 'IT Services' },
  '119': { name: 'Software Development' },
  '111': { name: 'Human Resources' },
  '102': { name: 'Marketing' },
  '123': { name: 'Product Marketing' },
  '109': { name: 'Communications' },
  '105': { name: 'Finance' },
  '121': { name: 'Investors' },
  '100': { name: 'General Management' },
  '124': { name: 'Management / Business Consulting' },
  '114': { name: 'Strategy' },
  '104': { name: 'Sales and Business Development' },
  '106': { name: 'Product Management' },
  '107': { name: 'Design' },
  '112': { name: 'Data and Analytics' },
  '103': { name: 'Engineering (non-Software)' },
  '108': { name: 'Operations' },
  '125': { name: 'Procurement' },
  '110': { name: 'Customer Service and Support' },
  '113': { name: 'Project and Portfolio Management' },
  '115': { name: 'Legal and Compliance' },
  '120': { name: 'Audit' },
  '117': { name: 'Supply Chain' },
  '118': { name: 'Research and Development' },
  '126': { name: 'Real Estate' },
  '1000': { name: 'Other' },
}

export const GPI_FUNCTIONS_ARRAY = Object.keys(GPI_FUNCTIONS).map((id) => ({
  id: id,
  name: GPI_FUNCTIONS[id].name,
}))
