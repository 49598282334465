import { normalize } from '@/util/iterators'

export type TagBase = { id: string; name: string; slug: string; parentId?: string }

export type Tag = {
  name: string
  slug: string
  subTags?: { [x: string]: Tag }
}

export const ACTIVE_DIRECTORY = '621c9de121b4850001fc33a7'
export const AI_AND_MACHINE_LEARNING = '621c9de121b4850001fc33bc'
export const APPLICATION_ARCHITECTURE = '621c9de121b4850001fc337b'
export const APPLICATIONS_AND_PLATFORMS = '621c9de121b4850001fc3362'
export const ASSET_AND_CONFIGURATION_MANAGEMENT = '621c9de121b4850001fc33f9'
export const ASSET_MANAGEMENT = '621c9de121b4850001fc33f5'
export const AUDITING_AND_LOGGING = '621c9de121b4850001fc33f4'
export const AUGMENTED_AND_VIRTUAL_REALITY_AR_VR = '621c9de121b4850001fc33da'
export const AUTOMATION = '621c9de121b4850001fc33b9'
export const AVAILABILITY = '621c9de121b4850001fc337c'
export const AVAILABILITY_AND_CAPACITY_MANAGEMENT = '621c9de121b4850001fc33fd'
export const AWARENESS_AND_TRAINING = '621c9de121b4850001fc33ca'
export const BACKUP_AND_DISASTER_RECOVERY = '621c9de121b4850001fc3393'
export const BIG_DATA = '621c9de121b4850001fc33c7'
export const BLOCKCHAIN = '621c9de121b4850001fc33dd'
export const BOARD_ENGAGEMENT = '621c9de121b4850001fc3408'
export const BOTS = '621c9de121b4850001fc33e1'
export const BUSINESS_APPLICATIONS = '621c9de121b4850001fc339e'
export const BUSINESS_CONTINUITY_AND_DISASTER_RECOVERY = '621c9de121b4850001fc3378'
export const BUSINESS_INTELLIGENCE = '621c9de121b4850001fc33c6'
export const BUSINESS_PROCESS_OUTSOURCING_BPO = '621c9de121b4850001fc33ed'
export const BUSINESS_RELATIONSHIPS = '621c9de121b4850001fc3407'
export const CALL_ROUTING_AND_IVR = '621c9de121b4850001fc33e9'
export const CHANGE_AND_RELEASE_MANAGEMENT = '621c9de121b4850001fc33fc'
export const CLOUD = '621c9de121b4850001fc335f'
export const COLLABORATION_SOLUTIONS = '621c9de121b4850001fc33ad'
export const COLOCATION = '621c9de121b4850001fc3380'
export const COMPUTE = '621c9de121b4850001fc3390'
export const CONFIGURATION = '621c9de121b4850001fc33f1'
export const CONSOLIDATION = '621c9de121b4850001fc3386'
export const CONSULTING_AND_PROFESSIONAL_SERVICES = '621c9de121b4850001fc3374'
export const CONTACT_CENTER_AND_TELECOM = '621c9de121b4850001fc3368'
export const CONTACT_CENTER_AS_A_SERVICE_CCAAS = '621c9de121b4850001fc33e6'
export const CONTACT_CENTER_SOLUTIONS = '621c9de121b4850001fc33e5'
export const CONTAINERS = '621c9de121b4850001fc3391'
export const CONTINUOUS_INTEGRATION_CONTINUOUS_DEPLOYMENT_CI_CD = '621c9de121b4850001fc33b7'
export const COST_OPTIMIZATION = '621c9de121b4850001fc33ff'
export const CRISIS_MANAGEMENT = '621c9de121b4850001fc33fa'
export const CROWDSTRIKE_OUTAGE_2024 = '669ac4379934d47c363b1f05'
export const CRYPTOCURRENCY = '621c9de121b4850001fc33e0'
export const CRYPTOCURRENCY_AND_BITCOIN = '621c9de121b4850001fc33db'
export const CULTURE_AND_VALUES = '621c9de121b4850001fc336f'
export const CUSTOMER_ENGAGEMENT = '621c9de121b4850001fc340a'
export const CUSTOMER_RELATIONSHIP_MANAGEMENT_CRM = '621c9de121b4850001fc33a4'
export const DASHBOARDS_AND_VISUALIZATION = '621c9de121b4850001fc33c4'
export const DATA_AND_ANALYTICS = '621c9de121b4850001fc3365'
export const DATA_CENTER = '621c9de121b4850001fc335e'
export const DATA_CENTER_DESIGN = '621c9de121b4850001fc3382'
export const DATA_CENTER_STRATEGY = '621c9de121b4850001fc3383'
export const DATA_GOVERNANCE = '621c9de121b4850001fc33be'
export const DATA_INTEGRATIONS = '621c9de121b4850001fc33c1'
export const DATA_LAKE = '621c9de121b4850001fc33c2'
export const DATA_MANAGEMENT = '621c9de121b4850001fc33c5'
export const DATA_PRIVACY = '621c9de121b4850001fc33cb'
export const DATA_PROTECTION_AND_ENCRYPTION = '621c9de121b4850001fc33cc'
export const DATA_SCIENCE_AND_ALGORITHMS = '621c9de121b4850001fc33c8'
export const DATA_WAREHOUSE = '621c9de121b4850001fc33c0'
export const DATABASE = '621c9de121b4850001fc3361'
export const DATABASE_OPTIMIZATION = '621c9de121b4850001fc339c'
export const DEVICE_MANAGEMENT = '621c9de121b4850001fc33f2'
export const DEVOPS = '621c9de121b4850001fc33b5'
export const DISRUPTIVE_AND_EMERGING_TECHNOLOGIES = '621c9de121b4850001fc3367'
export const DOCUMENT_DATABASES = '621c9de121b4850001fc339a'
export const DOCUMENT_MANAGEMENT = '621c9de121b4850001fc33ae'
export const EMPLOYEE_ENGAGEMENT = '621c9de121b4850001fc3409'
export const END_USER_DEVICES = '621c9de121b4850001fc33aa'
export const END_USER_SERVICES_AND_COLLABORATION = '621c9de121b4850001fc3363'
export const ENGINEERING = '621c9de121b4850001fc3364'
export const ENTERPRISE_AND_IT_SERVICE_MANAGEMENT_ITSM = '621c9de121b4850001fc33f8'
export const ENTERPRISE_APPLICATIONS = '621c9de121b4850001fc339f'
export const ENTERPRISE_ARCHITECTURE = '621c9de121b4850001fc3379'
export const ENTERPRISE_RESOURCE_PLANNING_ERP = '621c9de121b4850001fc33a3'
export const EVENTS = '621c9de121b4850001fc3411'
export const EXTERNAL_CXO_RELATIONSHIPS = '621c9de121b4850001fc340c'
export const FEEDBACK = '621c9de121b4850001fc340e'
export const FINANCE = '621c9de121b4850001fc33a0'
export const FINANCIAL_MANAGEMENT = '621c9de121b4850001fc33fe'
export const FIREWALL = '621c9de121b4850001fc33d5'
export const FIVE_G = '621c9de121b4850001fc33e2'
export const FOURTH_INDUSTRIAL_REVOLUTION = '621c9de121b4850001fc33e3'
export const GENERATIVE_AI = '64b0077d59e5599a1843d8e0'
export const GOVERNANCE_RISK_AND_COMPLIANCE = '621c9de121b4850001fc33d8'
export const GRAPH_DATABASES = '621c9de121b4850001fc339b'
export const HUMAN_RESOURCES_HRIS = '621c9de121b4850001fc33a2'
export const HVAC_POWER_AND_COOLING = '621c9de121b4850001fc3384'
export const HYBRID_CLOUD = '621c9de121b4850001fc3389'
export const HYPERCONVERGED = '621c9de121b4850001fc3395'
export const IAAS = '621c9de121b4850001fc338b'
export const IDENTITY_AND_ACCESS_MANAGEMENT__IAM_ = '621c9de121b4850001fc33c9'
export const IN_MEMORY = '621c9de121b4850001fc3399'
export const INCIDENT_AND_PROBLEM_MANAGEMENT = '621c9de121b4850001fc33fb'
export const INFRASTRUCTURE = '621c9de121b4850001fc3360'
export const INNOVATION = '621c9de121b4850001fc33df'
export const INTEGRATIONS = '621c9de121b4850001fc33a8'
export const INTERCONNECTION = '621c9de121b4850001fc3381'
export const INTERNAL_CXO_RELATIONSHIPS = '621c9de121b4850001fc340b'
export const IOT = '621c9de121b4850001fc33de'
export const IT_STRATEGY_AND_ROADMAP = '621c9de121b4850001fc3377'
export const JOBS = '621c9de121b4850001fc3410'
export const KEY_MANAGEMENT_AND_HSM = '621c9de121b4850001fc33d6'
export const KEY_VALUE = '621c9de121b4850001fc3397'
export const KPIS_METRICS_AND_REPORTING = '621c9de121b4850001fc3403'
export const LEGAL = '621c9de121b4850001fc33a1'
export const LICENSE_MANAGEMENT = '621c9de121b4850001fc3401'
export const MANAGEMENT_TOOLS = '621c9de121b4850001fc3369'
export const MENTORING_AND_COACHING = '621c9de121b4850001fc3371'
export const MIGRATION = '621c9de121b4850001fc3385'
export const MOBILE = '621c9de121b4850001fc33a6'
export const MOBILE_DEVELOPMENT = '621c9de121b4850001fc33b4'
export const MOBILE_DEVICE_MANAGEMENT_MDM = '621c9de121b4850001fc33f3'
export const MOBILE_DEVICES = '621c9de121b4850001fc33ab'
export const MODERNIZATION = '621c9de121b4850001fc33a5'
export const MONITORING = '621c9de121b4850001fc33ef'
export const MULTI_CLOUD = '621c9de121b4850001fc338a'
export const NATURAL_LANGUAGE_PROCESSING_NLP = '621c9de121b4850001fc33bd'
export const NETWORK = '621c9de121b4850001fc338f'
export const NEWS = '621c9de121b4850001fc340f'
export const NO_CODE = '621c9de121b4850001fc33a9'
export const NOSQL_DATABASES = '621c9de121b4850001fc3398'
export const OMNICHANNEL = '621c9de121b4850001fc33eb'
export const OPERATIONS_MANAGEMENT = '621c9de121b4850001fc3394'
export const OUTSOURCING_AND_MANAGED_SERVICES = '621c9de121b4850001fc3373'
export const PAAS = '621c9de121b4850001fc338c'
export const PARTNERSHIPS = '621c9de121b4850001fc340d'
export const PBX_INFRASTRUCTURE = '621c9de121b4850001fc33e7'
export const PEER_INSIGHTS = '621c9de121b4850001fc336c'
export const PEOPLE_AND_LEADERSHIP = '621c9de121b4850001fc335c'
export const PERFORMANCE = '621c9de121b4850001fc337f'
export const PERSONAL_DEVELOPMENT = '621c9de121b4850001fc3375'
export const PORTFOLIO_PROGRAM_AND_PROJECT_MANAGEMENT = '621c9de121b4850001fc33f7'
export const PREDICTIVE_ANALYTICS = '621c9de121b4850001fc33bf'
export const PRIVATE_CLOUD = '621c9de121b4850001fc3387'
export const PROCESS_MANAGEMENT = '621c9de121b4850001fc336a'
export const PROCUREMENT = '621c9de121b4850001fc3404'
export const PRODUCTIVITY_TOOLS = '621c9de121b4850001fc33ac'
export const PROJECT_MANAGEMENT = '621c9de121b4850001fc33f6'
export const PROVISIONING = '621c9de121b4850001fc33ee'
export const PUBLIC_CLOUD = '621c9de121b4850001fc3388'
export const QUALITY_ASSURANCE = '621c9de121b4850001fc33b6'
export const REAL_TIME_DATA_MOVEMENT = '621c9de121b4850001fc33c3'
export const REGULATORY_COMPLIANCE = '621c9de121b4850001fc33d7'
export const RELATIONAL_OR_STRUCTURED_DATABASES = '621c9de121b4850001fc3396'
export const RELATIONSHIP_MANAGEMENT = '621c9de121b4850001fc336b'
export const REMOTE_SMART_HANDS = '621c9de121b4850001fc33b0'
export const RESILIENCE = '621c9de121b4850001fc337e'
export const RISK_MANAGEMENT = '621c9de121b4850001fc33d9'
export const ROBOTIC_PROCESS_AUTOMATION_RPA = '621c9de121b4850001fc33dc'
export const RUSSIAN_INVASION_OF_UKRAINE = '6225d1e33df02bda7c12dc24'
export const SAAS = '621c9de121b4850001fc338d'
export const SCALABILITY = '621c9de121b4850001fc337d'
export const SCRIPTING = '621c9de121b4850001fc33bb'
export const SECURE_CODE_AND_AUTOMATION_DEVSECOPS = '621c9de121b4850001fc33d2'
export const SECURITY = '621c9de121b4850001fc33f0'
export const SECURITY_AND_GRC = '621c9de121b4850001fc3366'
export const SECURITY_FRAMEWORKS_NIST_CIS_CSF = '621c9de121b4850001fc33cf'
export const SECURITY_OPERATIONS_CENTER_SOC = '621c9de121b4850001fc33d4'
export const SECURITY_STRATEGY_AND_ROADMAP = '621c9de121b4850001fc3376'
export const SERVERLESS = '621c9de121b4850001fc339d'
export const SERVICE_DESK = '621c9de121b4850001fc3402'
export const SINGLE_SIGN_ON_SSO_SAML = '621c9de121b4850001fc33d3'
export const SMART_CITIES_AND_SUSTAINABILITY = '621c9de121b4850001fc33e4'
export const SOFTWARE_DEVELOPMENT = '621c9de121b4850001fc33b3'
export const SOFTWARE_DEVELOPMENT_LIFE_CYCLE_SDLC = '621c9de121b4850001fc33ba'
export const SOFTWARE_PACKAGING_AND_DISTRIBUTION = '621c9de121b4850001fc33b1'
export const SOLUTIONS_ARCHITECTURE = '621c9de121b4850001fc337a'
export const STORAGE = '621c9de121b4850001fc338e'
export const STRATEGY_AND_ARCHITECTURE = '621c9de121b4850001fc335d'
export const TALENT_MANAGEMENT_AND_PERFORMANCE = '621c9de121b4850001fc336e'
export const TALENT_SOURCING_AND_HIRING = '621c9de121b4850001fc3370'
export const TEAM_AND_ORGANIZATIONAL_DESIGN = '621c9de121b4850001fc336d'
export const TECHNICAL_PRODUCT_MANAGEMENT = '621c9de121b4850001fc33b2'
export const TESTING = '621c9de121b4850001fc33b8'
export const THIRD_PARTY_RISK_MANAGEMENT_TPRM = '621c9de121b4850001fc33d0'
export const THREAT_AND_VULNERABILITY_MANAGEMENT = '621c9de121b4850001fc33ce'
export const THREAT_INTELLIGENCE_AND_INCIDENT_RESPONSE = '621c9de121b4850001fc33cd'
export const TRAINING_AND_CERTIFICATION = '621c9de121b4850001fc3372'
export const UNIFIED_COMMUNICATIONS = '621c9de121b4850001fc33ec'
export const VENDOR_MANAGEMENT = '621c9de121b4850001fc3400'
export const VENDOR_PRODUCT_ASSESSMENT = '621c9de121b4850001fc3405'
export const VENDOR_PRODUCT_RECOMMENDATION = '621c9de121b4850001fc3406'
export const VIRTUAL_DESKTOP_INFRASTRUCTURE_VDI = '621c9de121b4850001fc33af'
export const VIRTUAL_INFRASTRUCTURE = '621c9de121b4850001fc3392'
export const VOICE_ANALYTICS = '621c9de121b4850001fc33e8'
export const VOICE_OVER_IP_VOIP = '621c9de121b4850001fc33ea'
export const ZERO_TRUST = '621c9de121b4850001fc33d1'

export const Tags: TagBase[] = [
  {
    id: APPLICATIONS_AND_PLATFORMS,
    name: 'Applications & Platforms',
    slug: 'applications-and-platforms',
  },
  { id: CLOUD, name: 'Cloud', slug: 'cloud' },
  {
    id: CONTACT_CENTER_AND_TELECOM,
    name: 'Contact Center & Telecom',
    slug: 'contact-center-and-telecom',
  },
  {
    id: DATA_AND_ANALYTICS,
    name: 'Data & Analytics',
    slug: 'data-and-analytics',
  },
  { id: DATA_CENTER, name: 'Data Center', slug: 'data-center' },
  { id: DATABASE, name: 'Database', slug: 'database' },
  {
    id: DISRUPTIVE_AND_EMERGING_TECHNOLOGIES,
    name: 'Disruptive & Emerging Technologies',
    slug: 'disruptive-and-emerging-technologies',
  },
  {
    id: END_USER_SERVICES_AND_COLLABORATION,
    name: 'End-User Services & Collaboration',
    slug: 'end-user-services-and-collaboration',
  },
  { id: ENGINEERING, name: 'Engineering', slug: 'engineering' },
  {
    id: INFRASTRUCTURE,
    name: 'Infrastructure',
    slug: 'infrastructure',
  },
  {
    id: MANAGEMENT_TOOLS,
    name: 'Management Tools',
    slug: 'management-tools',
  },
  {
    id: PROCESS_MANAGEMENT,
    name: 'Process Management',
    slug: 'process-management',
  },
  {
    id: RELATIONSHIP_MANAGEMENT,
    name: 'Relationship Management',
    slug: 'relationship-management',
  },
  {
    id: RUSSIAN_INVASION_OF_UKRAINE,
    name: 'Russian Invasion of Ukraine',
    slug: '2022-russia-invasion-ukraine',
  },
  {
    id: SECURITY_AND_GRC,
    name: 'Security & GRC',
    slug: 'security-and-grc',
  },
  {
    id: STRATEGY_AND_ARCHITECTURE,
    name: 'Strategy & Architecture',
    slug: 'strategy-and-architecture',
  },
  {
    id: GOVERNANCE_RISK_AND_COMPLIANCE,
    name: 'Governance, Risk & Compliance',
    slug: 'governance-risk-and-compliance',
    parentId: SECURITY_AND_GRC,
  },
  {
    id: BUSINESS_APPLICATIONS,
    name: 'Business Applications',
    slug: 'business-applications',
    parentId: APPLICATIONS_AND_PLATFORMS,
  },
  {
    id: ENTERPRISE_APPLICATIONS,
    name: 'Enterprise Applications',
    slug: 'enterprise-applications',
    parentId: APPLICATIONS_AND_PLATFORMS,
  },
  { id: SECURITY, name: 'Security', slug: 'security', parentId: MANAGEMENT_TOOLS },
  {
    id: PEER_INSIGHTS,
    name: 'Peer Insights',
    slug: 'peer-insights',
  },
  {
    id: PEOPLE_AND_LEADERSHIP,
    name: 'People & Leadership',
    slug: 'people-and-leadership',
  },
  {
    id: TEAM_AND_ORGANIZATIONAL_DESIGN,
    name: 'Team & Organizational Design',
    slug: 'team-and-organizational-design',
    parentId: PEOPLE_AND_LEADERSHIP,
  },
  {
    id: TALENT_MANAGEMENT_AND_PERFORMANCE,
    name: 'Talent Management & Performance',
    slug: 'talent-management-and-performance',
    parentId: PEOPLE_AND_LEADERSHIP,
  },
  {
    id: CULTURE_AND_VALUES,
    name: 'Culture & Values',
    slug: 'culture-and-values',
    parentId: PEOPLE_AND_LEADERSHIP,
  },
  {
    id: TALENT_SOURCING_AND_HIRING,
    name: 'Talent Sourcing & Hiring',
    slug: 'talent-sourcing-and-hiring',
    parentId: PEOPLE_AND_LEADERSHIP,
  },
  {
    id: MENTORING_AND_COACHING,
    name: 'Mentoring & Coaching',
    slug: 'mentoring-and-coaching',
    parentId: PEOPLE_AND_LEADERSHIP,
  },
  {
    id: TRAINING_AND_CERTIFICATION,
    name: 'Training & Certification',
    slug: 'training-and-certification',
    parentId: PEOPLE_AND_LEADERSHIP,
  },
  {
    id: OUTSOURCING_AND_MANAGED_SERVICES,
    name: 'Outsourcing & Managed Services',
    slug: 'outsourcing-and-managed-services',
    parentId: PEOPLE_AND_LEADERSHIP,
  },
  {
    id: CONSULTING_AND_PROFESSIONAL_SERVICES,
    name: 'Consulting & Professional Services',
    slug: 'consulting-and-professional-services',
    parentId: PEOPLE_AND_LEADERSHIP,
  },
  {
    id: PERSONAL_DEVELOPMENT,
    name: 'Personal Development',
    slug: 'personal-development',
    parentId: PEOPLE_AND_LEADERSHIP,
  },
  {
    id: SECURITY_STRATEGY_AND_ROADMAP,
    name: 'Security Strategy & Roadmap',
    slug: 'security-strategy-and-roadmap',
    parentId: STRATEGY_AND_ARCHITECTURE,
  },
  {
    id: IT_STRATEGY_AND_ROADMAP,
    name: 'IT Strategy & Roadmap',
    slug: 'it-strategy-and-roadmap',
    parentId: STRATEGY_AND_ARCHITECTURE,
  },
  {
    id: BUSINESS_CONTINUITY_AND_DISASTER_RECOVERY,
    name: 'Business Continuity & Disaster Recovery',
    slug: 'business-continuity-and-disaster-recovery',
    parentId: STRATEGY_AND_ARCHITECTURE,
  },
  {
    id: ENTERPRISE_ARCHITECTURE,
    name: 'Enterprise Architecture',
    slug: 'enterprise-architecture',
    parentId: STRATEGY_AND_ARCHITECTURE,
  },
  {
    id: SOLUTIONS_ARCHITECTURE,
    name: 'Solutions Architecture',
    slug: 'solutions-architecture',
    parentId: STRATEGY_AND_ARCHITECTURE,
  },
  {
    id: APPLICATION_ARCHITECTURE,
    name: 'Application Architecture',
    slug: 'application-architecture',
    parentId: STRATEGY_AND_ARCHITECTURE,
  },
  {
    id: AVAILABILITY,
    name: 'Availability',
    slug: 'availability',
    parentId: STRATEGY_AND_ARCHITECTURE,
  },
  {
    id: SCALABILITY,
    name: 'Scalability',
    slug: 'scalability',
    parentId: STRATEGY_AND_ARCHITECTURE,
  },
  {
    id: RESILIENCE,
    name: 'Resilience',
    slug: 'resilience',
    parentId: STRATEGY_AND_ARCHITECTURE,
  },
  {
    id: PERFORMANCE,
    name: 'Performance',
    slug: 'performance',
    parentId: STRATEGY_AND_ARCHITECTURE,
  },
  { id: COLOCATION, name: 'Colocation', slug: 'colocation', parentId: DATA_CENTER },
  {
    id: INTERCONNECTION,
    name: 'Interconnection',
    slug: 'interconnection',
    parentId: DATA_CENTER,
  },
  {
    id: DATA_CENTER_DESIGN,
    name: 'Data Center Design',
    slug: 'data-center-design',
    parentId: DATA_CENTER,
  },
  {
    id: DATA_CENTER_STRATEGY,
    name: 'Data Center Strategy',
    slug: 'data-center-strategy',
    parentId: DATA_CENTER,
  },
  {
    id: HVAC_POWER_AND_COOLING,
    name: 'HVAC, Power & Cooling',
    slug: 'hvac-power-and-cooling',
    parentId: DATA_CENTER,
  },
  { id: MIGRATION, name: 'Migration', slug: 'migration', parentId: DATA_CENTER },
  {
    id: CONSOLIDATION,
    name: 'Consolidation',
    slug: 'consolidation',
    parentId: DATA_CENTER,
  },
  {
    id: PRIVATE_CLOUD,
    name: 'Private Cloud',
    slug: 'private-cloud',
    parentId: CLOUD,
  },
  { id: PUBLIC_CLOUD, name: 'Public Cloud', slug: 'public-cloud', parentId: CLOUD },
  { id: HYBRID_CLOUD, name: 'Hybrid Cloud', slug: 'hybrid-cloud', parentId: CLOUD },
  { id: MULTI_CLOUD, name: 'Multi-Cloud', slug: 'multi-cloud', parentId: CLOUD },
  { id: IAAS, name: 'IaaS', slug: 'iaas', parentId: CLOUD },
  { id: PAAS, name: 'PaaS', slug: 'paas', parentId: CLOUD },
  { id: SAAS, name: 'SaaS', slug: 'saas', parentId: CLOUD },
  { id: STORAGE, name: 'Storage', slug: 'storage', parentId: INFRASTRUCTURE },
  { id: NETWORK, name: 'Network', slug: 'network', parentId: INFRASTRUCTURE },
  { id: COMPUTE, name: 'Compute', slug: 'compute', parentId: INFRASTRUCTURE },
  {
    id: CONTAINERS,
    name: 'Containers',
    slug: 'containers',
    parentId: INFRASTRUCTURE,
  },
  {
    id: VIRTUAL_INFRASTRUCTURE,
    name: 'Virtual Infrastructure',
    slug: 'virtual-infrastructure',
    parentId: INFRASTRUCTURE,
  },
  {
    id: BACKUP_AND_DISASTER_RECOVERY,
    name: 'Backup & Disaster Recovery',
    slug: 'backup-and-disaster-recovery',
    parentId: INFRASTRUCTURE,
  },
  {
    id: OPERATIONS_MANAGEMENT,
    name: 'Operations Management',
    slug: 'operations-management',
    parentId: INFRASTRUCTURE,
  },
  {
    id: HYPERCONVERGED,
    name: 'Hyperconverged',
    slug: 'hyperconverged',
    parentId: INFRASTRUCTURE,
  },
  {
    id: RELATIONAL_OR_STRUCTURED_DATABASES,
    name: 'Relational or Structured Databases',
    slug: 'relational-or-structured-databases',
    parentId: DATABASE,
  },
  { id: KEY_VALUE, name: 'Key-value', slug: 'key-value', parentId: DATABASE },
  {
    id: NOSQL_DATABASES,
    name: 'NoSQL Databases',
    slug: 'nosql-databases',
    parentId: DATABASE,
  },
  { id: IN_MEMORY, name: 'In-memory', slug: 'in-memory', parentId: DATABASE },
  {
    id: DOCUMENT_DATABASES,
    name: 'Document Databases',
    slug: 'document-databases',
    parentId: DATABASE,
  },
  {
    id: GRAPH_DATABASES,
    name: 'Graph Databases',
    slug: 'graph-databases',
    parentId: DATABASE,
  },
  {
    id: DATABASE_OPTIMIZATION,
    name: 'Database Optimization',
    slug: 'database-optimization',
    parentId: DATABASE,
  },
  { id: SERVERLESS, name: 'Serverless', slug: 'serverless', parentId: DATABASE },
  {
    id: FINANCE,
    name: 'Finance',
    slug: 'finance',
    parentId: APPLICATIONS_AND_PLATFORMS,
  },
  { id: LEGAL, name: 'Legal', slug: 'legal', parentId: APPLICATIONS_AND_PLATFORMS },
  {
    id: HUMAN_RESOURCES_HRIS,
    name: 'Human Resources (HRIS)',
    slug: 'human-resources',
    parentId: APPLICATIONS_AND_PLATFORMS,
  },
  {
    id: ENTERPRISE_RESOURCE_PLANNING_ERP,
    name: 'Enterprise Resource Planning (ERP)',
    slug: 'enterprise-resource-planning',
    parentId: APPLICATIONS_AND_PLATFORMS,
  },
  {
    id: CUSTOMER_RELATIONSHIP_MANAGEMENT_CRM,
    name: 'Customer Relationship Management (CRM)',
    slug: 'customer-relationship-management',
    parentId: APPLICATIONS_AND_PLATFORMS,
  },
  {
    id: MODERNIZATION,
    name: 'Modernization',
    slug: 'modernization',
    parentId: APPLICATIONS_AND_PLATFORMS,
  },
  { id: MOBILE, name: 'Mobile', slug: 'mobile', parentId: APPLICATIONS_AND_PLATFORMS },
  {
    id: ACTIVE_DIRECTORY,
    name: 'Active Directory',
    slug: 'active-directory',
    parentId: APPLICATIONS_AND_PLATFORMS,
  },
  {
    id: INTEGRATIONS,
    name: 'Integrations',
    slug: 'integrations',
    parentId: APPLICATIONS_AND_PLATFORMS,
  },
  {
    id: NO_CODE,
    name: 'No-Code',
    slug: 'no-code',
    parentId: APPLICATIONS_AND_PLATFORMS,
  },
  {
    id: END_USER_DEVICES,
    name: 'End-User Devices',
    slug: 'end-user-devices',
    parentId: END_USER_SERVICES_AND_COLLABORATION,
  },
  {
    id: MOBILE_DEVICES,
    name: 'Mobile Devices',
    slug: 'mobile-devices',
    parentId: END_USER_SERVICES_AND_COLLABORATION,
  },
  {
    id: PRODUCTIVITY_TOOLS,
    name: 'Productivity Tools',
    slug: 'productivity-tools',
    parentId: END_USER_SERVICES_AND_COLLABORATION,
  },
  {
    id: COLLABORATION_SOLUTIONS,
    name: 'Collaboration Solutions',
    slug: 'collaboration-solutions',
    parentId: END_USER_SERVICES_AND_COLLABORATION,
  },
  {
    id: DOCUMENT_MANAGEMENT,
    name: 'Document Management',
    slug: 'document-management',
    parentId: END_USER_SERVICES_AND_COLLABORATION,
  },
  {
    id: VIRTUAL_DESKTOP_INFRASTRUCTURE_VDI,
    name: 'Virtual Desktop Infrastructure (VDI)',
    slug: 'virtual-desktop-infrastructure',
    parentId: END_USER_SERVICES_AND_COLLABORATION,
  },
  {
    id: REMOTE_SMART_HANDS,
    name: 'Remote/Smart Hands',
    slug: 'remote-smart-hands',
    parentId: END_USER_SERVICES_AND_COLLABORATION,
  },
  {
    id: SOFTWARE_PACKAGING_AND_DISTRIBUTION,
    name: 'Software Packaging & Distribution',
    slug: 'software-packaging-and-distribution',
    parentId: END_USER_SERVICES_AND_COLLABORATION,
  },
  {
    id: TECHNICAL_PRODUCT_MANAGEMENT,
    name: 'Technical Product Management',
    slug: 'technical-product-management',
    parentId: ENGINEERING,
  },
  {
    id: SOFTWARE_DEVELOPMENT,
    name: 'Software Development',
    slug: 'software-development',
    parentId: ENGINEERING,
  },
  {
    id: MOBILE_DEVELOPMENT,
    name: 'Mobile Development',
    slug: 'mobile-development',
    parentId: ENGINEERING,
  },
  { id: DEVOPS, name: 'DevOps', slug: 'devops', parentId: ENGINEERING },
  {
    id: QUALITY_ASSURANCE,
    name: 'Quality Assurance',
    slug: 'quality-assurance',
    parentId: ENGINEERING,
  },
  {
    id: CONTINUOUS_INTEGRATION_CONTINUOUS_DEPLOYMENT_CI_CD,
    name: 'Continuous Integration/Continuous Deployment (CI/CD)',
    slug: 'continuous-integration-continuous-deployment',
    parentId: ENGINEERING,
  },
  { id: TESTING, name: 'Testing', slug: 'testing', parentId: ENGINEERING },
  { id: AUTOMATION, name: 'Automation', slug: 'automation', parentId: ENGINEERING },
  {
    id: SOFTWARE_DEVELOPMENT_LIFE_CYCLE_SDLC,
    name: 'Software Development Life Cycle (SDLC)',
    slug: 'software-development-life-cycle',
    parentId: ENGINEERING,
  },
  { id: SCRIPTING, name: 'Scripting', slug: 'scripting', parentId: ENGINEERING },
  {
    id: AI_AND_MACHINE_LEARNING,
    name: 'AI & Machine Learning',
    slug: 'artificial-intelligence-and-machine-learning',
    parentId: DATA_AND_ANALYTICS,
  },
  {
    id: NATURAL_LANGUAGE_PROCESSING_NLP,
    name: 'Natural Language Processing (NLP)',
    slug: 'natural-language-processing',
    parentId: DATA_AND_ANALYTICS,
  },
  {
    id: DATA_GOVERNANCE,
    name: 'Data Governance',
    slug: 'data-governance',
    parentId: DATA_AND_ANALYTICS,
  },
  {
    id: PREDICTIVE_ANALYTICS,
    name: 'Predictive Analytics',
    slug: 'predictive-analytics',
    parentId: DATA_AND_ANALYTICS,
  },
  {
    id: DATA_WAREHOUSE,
    name: 'Data Warehouse',
    slug: 'data-warehouse',
    parentId: DATA_AND_ANALYTICS,
  },
  {
    id: DATA_INTEGRATIONS,
    name: 'Data Integrations',
    slug: 'data-integrations',
    parentId: DATA_AND_ANALYTICS,
  },
  {
    id: DATA_LAKE,
    name: 'Data Lake',
    slug: 'data-lake',
    parentId: DATA_AND_ANALYTICS,
  },
  {
    id: REAL_TIME_DATA_MOVEMENT,
    name: 'Real Time Data Movement',
    slug: 'real-time-data-movement',
    parentId: DATA_AND_ANALYTICS,
  },
  {
    id: DASHBOARDS_AND_VISUALIZATION,
    name: 'Dashboards & Visualization',
    slug: 'dashboards-and-visualization',
    parentId: DATA_AND_ANALYTICS,
  },
  {
    id: DATA_MANAGEMENT,
    name: 'Data Management',
    slug: 'data-management',
    parentId: DATA_AND_ANALYTICS,
  },
  {
    id: BUSINESS_INTELLIGENCE,
    name: 'Business Intelligence',
    slug: 'business-intelligence',
    parentId: DATA_AND_ANALYTICS,
  },
  { id: BIG_DATA, name: 'Big Data', slug: 'big-data', parentId: DATA_AND_ANALYTICS },
  {
    id: DATA_SCIENCE_AND_ALGORITHMS,
    name: 'Data Science & Algorithms',
    slug: 'data-science-and-algorithms',
    parentId: DATA_AND_ANALYTICS,
  },
  {
    id: IDENTITY_AND_ACCESS_MANAGEMENT__IAM_,
    name: 'Identity & Access Management (IAM)',
    slug: 'identity-and-access-management',
    parentId: SECURITY_AND_GRC,
  },
  {
    id: AWARENESS_AND_TRAINING,
    name: 'Awareness & Training',
    slug: 'awareness-and-training',
    parentId: SECURITY_AND_GRC,
  },
  {
    id: DATA_PRIVACY,
    name: 'Data Privacy',
    slug: 'data-privacy',
    parentId: SECURITY_AND_GRC,
  },
  {
    id: DATA_PROTECTION_AND_ENCRYPTION,
    name: 'Data Protection & Encryption',
    slug: 'data-protection-and-encryption',
    parentId: SECURITY_AND_GRC,
  },
  {
    id: THREAT_INTELLIGENCE_AND_INCIDENT_RESPONSE,
    name: 'Threat Intelligence & Incident Response',
    slug: 'threat-intelligence-and-incident-response',
    parentId: SECURITY_AND_GRC,
  },
  {
    id: THREAT_AND_VULNERABILITY_MANAGEMENT,
    name: 'Threat & Vulnerability Management',
    slug: 'threat-and-vulnerability-management',
    parentId: SECURITY_AND_GRC,
  },
  {
    id: SECURITY_FRAMEWORKS_NIST_CIS_CSF,
    name: 'Security Frameworks (NIST, CIS, CSF)',
    slug: 'security-frameworks',
    parentId: SECURITY_AND_GRC,
  },
  {
    id: THIRD_PARTY_RISK_MANAGEMENT_TPRM,
    name: 'Third Party Risk Management (TPRM)',
    slug: 'third-party-risk-management',
    parentId: SECURITY_AND_GRC,
  },
  {
    id: ZERO_TRUST,
    name: 'Zero Trust',
    slug: 'zero-trust',
    parentId: SECURITY_AND_GRC,
  },
  {
    id: SECURE_CODE_AND_AUTOMATION_DEVSECOPS,
    name: 'Secure Code & Automation (DevSecOps)',
    slug: 'secure-code-and-automation',
    parentId: SECURITY_AND_GRC,
  },
  {
    id: SINGLE_SIGN_ON_SSO_SAML,
    name: 'Single Sign-On (SSO/SAML)',
    slug: 'single-sign-on',
    parentId: SECURITY_AND_GRC,
  },
  {
    id: SECURITY_OPERATIONS_CENTER_SOC,
    name: 'Security Operations Center (SOC)',
    slug: 'security-operations-center',
    parentId: SECURITY_AND_GRC,
  },
  { id: FIREWALL, name: 'Firewall', slug: 'firewall', parentId: SECURITY_AND_GRC },
  {
    id: KEY_MANAGEMENT_AND_HSM,
    name: 'Key Management & HSM',
    slug: 'key-management-and-hsm',
    parentId: SECURITY_AND_GRC,
  },
  {
    id: REGULATORY_COMPLIANCE,
    name: 'Regulatory Compliance',
    slug: 'regulatory-compliance',
    parentId: SECURITY_AND_GRC,
  },
  {
    id: RISK_MANAGEMENT,
    name: 'Risk Management',
    slug: 'risk-management',
    parentId: SECURITY_AND_GRC,
  },
  {
    id: AUGMENTED_AND_VIRTUAL_REALITY_AR_VR,
    name: 'Augmented & Virtual Reality (AR/VR)',
    slug: 'augmented-and-virtual-reality',
    parentId: DISRUPTIVE_AND_EMERGING_TECHNOLOGIES,
  },
  {
    id: CRYPTOCURRENCY_AND_BITCOIN,
    name: 'Cryptocurrency & Bitcoin',
    slug: 'cryptocurrency-and-bitcoin',
    parentId: DISRUPTIVE_AND_EMERGING_TECHNOLOGIES,
  },
  {
    id: ROBOTIC_PROCESS_AUTOMATION_RPA,
    name: 'Robotic Process Automation (RPA)',
    slug: 'robotic-process-automation',
    parentId: DISRUPTIVE_AND_EMERGING_TECHNOLOGIES,
  },
  {
    id: BLOCKCHAIN,
    name: 'Blockchain',
    slug: 'blockchain',
    parentId: DISRUPTIVE_AND_EMERGING_TECHNOLOGIES,
  },
  { id: IOT, name: 'IoT', slug: 'iot', parentId: DISRUPTIVE_AND_EMERGING_TECHNOLOGIES },
  {
    id: INNOVATION,
    name: 'Innovation',
    slug: 'innovation',
    parentId: DISRUPTIVE_AND_EMERGING_TECHNOLOGIES,
  },
  {
    id: CRYPTOCURRENCY,
    name: 'Cryptocurrency',
    slug: 'cryptocurrency',
    parentId: DISRUPTIVE_AND_EMERGING_TECHNOLOGIES,
  },
  { id: BOTS, name: 'Bots', slug: 'bots', parentId: DISRUPTIVE_AND_EMERGING_TECHNOLOGIES },
  { id: FIVE_G, name: '5G', slug: '5g', parentId: DISRUPTIVE_AND_EMERGING_TECHNOLOGIES },
  {
    id: FOURTH_INDUSTRIAL_REVOLUTION,
    name: 'Fourth Industrial Revolution',
    slug: 'fourth-industrial-revolution',
    parentId: DISRUPTIVE_AND_EMERGING_TECHNOLOGIES,
  },
  {
    id: SMART_CITIES_AND_SUSTAINABILITY,
    name: 'Smart Cities & Sustainability',
    slug: 'smart-cities-and-sustainability',
    parentId: DISRUPTIVE_AND_EMERGING_TECHNOLOGIES,
  },
  {
    id: GENERATIVE_AI,
    name: 'Generative AI',
    slug: 'generative-ai',
    parentId: DISRUPTIVE_AND_EMERGING_TECHNOLOGIES,
  },
  {
    id: CONTACT_CENTER_SOLUTIONS,
    name: 'Contact Center Solutions',
    slug: 'contact-center-solutions',
    parentId: CONTACT_CENTER_AND_TELECOM,
  },
  {
    id: CONTACT_CENTER_AS_A_SERVICE_CCAAS,
    name: 'Contact Center as a Service (CCaaS)',
    slug: 'contact-center-as-a-service',
    parentId: CONTACT_CENTER_AND_TELECOM,
  },
  {
    id: PBX_INFRASTRUCTURE,
    name: 'PBX Infrastructure',
    slug: 'pbx-infrastructure',
    parentId: CONTACT_CENTER_AND_TELECOM,
  },
  {
    id: VOICE_ANALYTICS,
    name: 'Voice Analytics',
    slug: 'voice-analytics',
    parentId: CONTACT_CENTER_AND_TELECOM,
  },
  {
    id: CALL_ROUTING_AND_IVR,
    name: 'Call Routing & IVR',
    slug: 'call-routing-and-ivr',
    parentId: CONTACT_CENTER_AND_TELECOM,
  },
  {
    id: VOICE_OVER_IP_VOIP,
    name: 'Voice over IP (VoIP)',
    slug: 'voice-over-ip',
    parentId: CONTACT_CENTER_AND_TELECOM,
  },
  {
    id: OMNICHANNEL,
    name: 'Omnichannel',
    slug: 'omnichannel',
    parentId: CONTACT_CENTER_AND_TELECOM,
  },
  {
    id: UNIFIED_COMMUNICATIONS,
    name: 'Unified Communications',
    slug: 'unified-communications',
    parentId: CONTACT_CENTER_AND_TELECOM,
  },
  {
    id: BUSINESS_PROCESS_OUTSOURCING_BPO,
    name: 'Business Process Outsourcing (BPO)',
    slug: 'business-process-outsourcing',
    parentId: CONTACT_CENTER_AND_TELECOM,
  },
  {
    id: PROVISIONING,
    name: 'Provisioning',
    slug: 'provisioning',
    parentId: MANAGEMENT_TOOLS,
  },
  {
    id: MONITORING,
    name: 'Monitoring',
    slug: 'monitoring',
    parentId: MANAGEMENT_TOOLS,
  },
  {
    id: CONFIGURATION,
    name: 'Configuration',
    slug: 'configuration',
    parentId: MANAGEMENT_TOOLS,
  },
  {
    id: DEVICE_MANAGEMENT,
    name: 'Device Management',
    slug: 'device-management',
    parentId: MANAGEMENT_TOOLS,
  },
  {
    id: MOBILE_DEVICE_MANAGEMENT_MDM,
    name: 'Mobile Device Management (MDM)',
    slug: 'mobile-device-management',
    parentId: MANAGEMENT_TOOLS,
  },
  {
    id: AUDITING_AND_LOGGING,
    name: 'Auditing & Logging',
    slug: 'auditing-and-logging',
    parentId: MANAGEMENT_TOOLS,
  },
  {
    id: ASSET_MANAGEMENT,
    name: 'Asset Management',
    slug: 'asset-management',
    parentId: MANAGEMENT_TOOLS,
  },
  {
    id: PROJECT_MANAGEMENT,
    name: 'Project Management',
    slug: 'project-management',
    parentId: MANAGEMENT_TOOLS,
  },
  {
    id: PORTFOLIO_PROGRAM_AND_PROJECT_MANAGEMENT,
    name: 'Portfolio, Program & Project Management',
    slug: 'portfolio-program-and-project-management',
    parentId: PROCESS_MANAGEMENT,
  },
  {
    id: ENTERPRISE_AND_IT_SERVICE_MANAGEMENT_ITSM,
    name: 'Enterprise & IT Service Management (ITSM)',
    slug: 'enterprise-and-it-service-management',
    parentId: PROCESS_MANAGEMENT,
  },
  {
    id: ASSET_AND_CONFIGURATION_MANAGEMENT,
    name: 'Asset & Configuration Management',
    slug: 'asset-and-configuration-management',
    parentId: PROCESS_MANAGEMENT,
  },
  {
    id: CRISIS_MANAGEMENT,
    name: 'Crisis Management',
    slug: 'crisis-management',
    parentId: PROCESS_MANAGEMENT,
  },
  {
    id: INCIDENT_AND_PROBLEM_MANAGEMENT,
    name: 'Incident & Problem Management',
    slug: 'incident-and-problem-management',
    parentId: PROCESS_MANAGEMENT,
  },
  {
    id: CHANGE_AND_RELEASE_MANAGEMENT,
    name: 'Change & Release Management',
    slug: 'change-and-release-management',
    parentId: PROCESS_MANAGEMENT,
  },
  {
    id: AVAILABILITY_AND_CAPACITY_MANAGEMENT,
    name: 'Availability & Capacity Management',
    slug: 'availability-and-capacity-management',
    parentId: PROCESS_MANAGEMENT,
  },
  {
    id: FINANCIAL_MANAGEMENT,
    name: 'Financial Management',
    slug: 'financial-management',
    parentId: PROCESS_MANAGEMENT,
  },
  {
    id: COST_OPTIMIZATION,
    name: 'Cost Optimization',
    slug: 'cost-optimization',
    parentId: PROCESS_MANAGEMENT,
  },
  {
    id: VENDOR_MANAGEMENT,
    name: 'Vendor Management',
    slug: 'vendor-management',
    parentId: PROCESS_MANAGEMENT,
  },
  {
    id: LICENSE_MANAGEMENT,
    name: 'License Management',
    slug: 'license-management',
    parentId: PROCESS_MANAGEMENT,
  },
  {
    id: SERVICE_DESK,
    name: 'Service Desk',
    slug: 'service-desk',
    parentId: PROCESS_MANAGEMENT,
  },
  {
    id: KPIS_METRICS_AND_REPORTING,
    name: 'KPIs, Metrics & Reporting',
    slug: 'kpis-metrics-and-reporting',
    parentId: PROCESS_MANAGEMENT,
  },
  {
    id: PROCUREMENT,
    name: 'Procurement',
    slug: 'procurement',
    parentId: PROCESS_MANAGEMENT,
  },
  {
    id: VENDOR_PRODUCT_ASSESSMENT,
    name: 'Vendor/Product Assessment',
    slug: 'vendor-product-assessment',
    parentId: PROCESS_MANAGEMENT,
  },
  {
    id: VENDOR_PRODUCT_RECOMMENDATION,
    name: 'Vendor/Product Recommendation',
    slug: 'vendor-product-recommendation',
    parentId: PROCESS_MANAGEMENT,
  },
  {
    id: BUSINESS_RELATIONSHIPS,
    name: 'Business Relationships',
    slug: 'business-relationships',
    parentId: RELATIONSHIP_MANAGEMENT,
  },
  {
    id: BOARD_ENGAGEMENT,
    name: 'Board Engagement',
    slug: 'board-engagement',
    parentId: RELATIONSHIP_MANAGEMENT,
  },
  {
    id: EMPLOYEE_ENGAGEMENT,
    name: 'Employee Engagement',
    slug: 'employee-engagement',
    parentId: RELATIONSHIP_MANAGEMENT,
  },
  {
    id: CUSTOMER_ENGAGEMENT,
    name: 'Customer Engagement',
    slug: 'customer-engagement',
    parentId: RELATIONSHIP_MANAGEMENT,
  },
  {
    id: INTERNAL_CXO_RELATIONSHIPS,
    name: 'Internal CXO Relationships',
    slug: 'internal-cxo-relationships',
    parentId: RELATIONSHIP_MANAGEMENT,
  },
  {
    id: EXTERNAL_CXO_RELATIONSHIPS,
    name: 'External CXO Relationships',
    slug: 'external-cxo-relationships',
    parentId: RELATIONSHIP_MANAGEMENT,
  },
  {
    id: PARTNERSHIPS,
    name: 'Partnerships',
    slug: 'partnerships',
    parentId: RELATIONSHIP_MANAGEMENT,
  },
  { id: FEEDBACK, name: 'Feedback', slug: 'feedback', parentId: PEER_INSIGHTS },
  { id: NEWS, name: 'News', slug: 'news', parentId: PEER_INSIGHTS },
  { id: JOBS, name: 'Jobs', slug: 'jobs', parentId: PEER_INSIGHTS },
  { id: EVENTS, name: 'Events', slug: 'events', parentId: PEER_INSIGHTS },
  { id: CROWDSTRIKE_OUTAGE_2024, name: 'Crowdstrike Outage 2024', slug: 'crowdstrike-outage-2024' },
]

export const tagsById = normalize(Tags, 'id')
export const tagsBySlug = normalize(Tags, 'slug')

export const TagsByGroup = Object.entries(tagsById)
  .filter(([, tag]) => !tag.parentId)
  .reduce(
    (acc, [key, value]) => {
      acc[key] = value

      const entries = Object.entries(tagsById)
        .filter(([, tag]) => tag.parentId === key)
        .map(([id, tag]) => [id, { ...tag, id }])

      if (entries.length > 0) {
        acc[key].subTags = Object.fromEntries(entries)
      }

      return acc
    },
    {} as { [id: string]: Tag }
  )

export const TagNames: { [x: string]: string } = {
  '0': 'All Tags',
  ...Object.values(tagsById).reduce(
    (acc, tag) => {
      acc[tag.id] = tag.name
      return acc
    },
    {} as Record<string, string>
  ),
}

export const TagSlugs: { [x: string]: string } = {
  '0': 'all-tags',
  ...Object.values(tagsById).reduce(
    (acc, tag) => {
      acc[tag.id] = tag.slug
      return acc
    },
    {} as Record<string, string>
  ),
}
