import Button from '@/components/core/Button'
import CloseIcon from '@/components/icons/CloseIcon'
import { DivProps } from '@/types/common'
import clsx from 'clsx'
import styles from './ModalHeader.module.scss'

type ModalHeaderProps = { onCloseHandler?: () => void } & DivProps
const ModalHeader = ({ onCloseHandler, className, children, ...props }: ModalHeaderProps) => (
  <div className={clsx(styles.modal_header, className)} {...props}>
    <div className={styles.content}>{children}</div>
    {onCloseHandler && (
      <Button
        onClick={onCloseHandler}
        className={styles.button_close}
        variant="ghost"
        aria-label="Close modal"
      >
        <CloseIcon />
      </Button>
    )}
  </div>
)

export default ModalHeader
