import { transformToPublicProfile } from '@/services/posts/transformers'
import { GetProfileResponse, ProfileType, StatsDTO } from '@/types/api'

export type PartialProfileType = Pick<
  ProfileType,
  | '_id'
  | 'titledisplay'
  | 'company_size'
  | 'company_name'
  | 'gpiindustries'
  | 'contribution_points'
  | 'pulsepoints'
  | 'pic'
  | 'email'
  | 'displayname'
  | 'slug'
  | 'country'
  | 'social_media'
  | 'is_ambassador'
  | 'firstname'
  | 'departments'
  | 'title_override'
  | 'verification_state'
  | 'departments'
  | 'first_seen'
  | 'customer'
  | 'level'
  | 'primary_gpi_function'
  | 'additional_gpi_functions'
  | 'groups_membership'
  | 'channels'
> &
  Pick<GetProfileResponse, 'connections_count'> & {
    is_readonly?: boolean
    has_demo_access?: boolean
    is_points_required?: boolean
    access_form_required?: boolean
    use_split_access_form?: boolean
    consumption_access_form_required?: boolean
    contribution_access_form_required?: boolean
  }

export const anonymousProfile: PartialProfileType = {
  _id: '',
  titledisplay: 'No Title - Anonymous',
  company_size: 20,
  gpiindustries: [100],
  contribution_points: 0,
  pulsepoints: 0,
  pic: 'http://profile_pic/anonymous.png',
  displayname: 'Anonymous User',
  email: 'anonymous@anonymous.com',
  slug: 'anonymous',
  country: 'country',
  is_ambassador: false,
  firstname: 'Anonymous',
  company_name: 'Company',
  title_override: 'Title override',
  verification_state: 0,
  departments: { ids: null, last_updated: '1690810077111' },
  is_readonly: true,
  first_seen: '',
  customer: false,
  level: undefined,
  primary_gpi_function: undefined,
  groups_membership: null,
  channels: [],
  connections_count: 0,
  additional_gpi_functions: [],
}

export const defaultProfileStats: StatsDTO = {
  written_answers_count: 0,
  profile_viewed_count: 0,
  upvotes_received_count: 0,
}

export const AnonymousPublicProfile = transformToPublicProfile(anonymousProfile)
