type CheckIconProps = {
  size?: number
  className?: string
}

const CheckIcon = ({ size = 20, className }: CheckIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M9.95809 19.9947C9.69132 19.9924 9.42785 19.9351 9.18401 19.8266C8.94017 19.718 8.72112 19.5605 8.5404 19.3637C7.52187 18.3744 6.18584 17.7806 4.77093 17.6881C2.86235 17.5642 2.42849 17.0045 2.35328 15.0555C2.25266 13.7664 1.70883 12.5524 0.814715 11.6208C-0.259787 10.3183 -0.283292 9.75525 0.814715 8.47633C1.81299 7.39705 2.38586 5.99055 2.42648 4.51901C2.39489 4.22525 2.42993 3.92812 2.529 3.64986C2.62807 3.37159 2.78859 3.11939 2.99857 2.91212C3.20854 2.70485 3.46253 2.54788 3.74153 2.45295C4.02054 2.35801 4.31733 2.32756 4.60972 2.36389C5.98839 2.3535 7.31535 1.83612 8.33894 0.909865C9.77407 -0.302387 10.2999 -0.292966 11.682 0.876857C12.6876 1.82225 14.0056 2.36252 15.3836 2.39421C16.8973 2.41104 17.5884 3.06768 17.6542 4.60926C17.7123 6.1253 18.3303 7.56553 19.3882 8.6501C19.5799 8.82392 19.7333 9.03594 19.8387 9.27262C19.944 9.50931 19.999 9.76544 20 10.0246C20.001 10.2838 19.9481 10.5404 19.8446 10.7779C19.7411 11.0154 19.5893 11.2286 19.3989 11.4039C18.2843 12.5031 17.6416 13.9953 17.6079 15.5626C17.636 15.8553 17.5987 16.1506 17.4987 16.4269C17.3987 16.7033 17.2384 16.9539 17.0297 17.1603C16.8209 17.3668 16.5689 17.5239 16.2919 17.6203C16.0149 17.7168 15.7201 17.75 15.4286 17.7177C14.6771 17.7199 13.9338 17.8741 13.243 18.171C12.5523 18.4679 11.9284 18.9015 11.4086 19.4459C11.2216 19.6381 10.9947 19.7869 10.7441 19.8818C10.4935 19.9766 10.2252 20.0152 9.95809 19.9947Z"
      fill="#AF931C"
    />
    <path
      d="M8.32247 14.7212C7.92527 14.7291 7.78493 14.4801 7.60685 14.304C6.5536 13.2617 5.54206 12.1738 4.44842 11.1759C3.76523 10.5521 4.18361 10.1707 4.65033 9.85151C5.11704 9.53234 5.3547 8.39472 6.26231 9.2774C6.29343 9.30786 6.32521 9.33765 6.35698 9.36745C6.96007 9.92434 7.55654 10.9209 8.16889 10.9309C9.005 10.9448 9.52471 9.84027 10.1761 9.21451C11.4022 8.03716 12.6033 6.8344 13.7794 5.60629C14.2216 5.14276 14.4831 5.18846 14.9214 5.63013C16.2196 6.93793 16.242 6.91606 14.9703 8.1795C12.9914 10.1457 11.0118 12.1102 9.03151 14.0729C8.79054 14.3159 8.5277 14.5358 8.32247 14.7212Z"
      fill="black"
    />
  </svg>
)

export default CheckIcon
