import React from 'react'

type ProfileAnonIconProps = {
  size?: number
} & React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>

const ProfileAnonIcon = ({ size = 24, className, ...props }: ProfileAnonIconProps) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width={size}
    height={size}
    viewBox={`0 0 32 32 `}
    className={className}
  >
    <path
      d="M19.2785 16.8241C20.4504 16.1504 21.3678 15.1067 21.8881 13.8552C22.4083 12.6036 22.5022 11.2145 22.1552 9.90387C21.8082 8.5932 21.0398 7.43444 19.9693 6.60781C18.8989 5.78117 17.5865 5.33301 16.2363 5.33301C14.8861 5.33301 13.5737 5.78117 12.5033 6.60781C11.4329 7.43444 10.6644 8.5932 10.3174 9.90387C9.97039 11.2145 10.0643 12.6036 10.5846 13.8552C11.1048 15.1067 12.0222 16.1504 13.1941 16.8241H8.5332V26.6663H10.1662V18.4643H22.4154V26.6663H24.0484V16.8241H19.2785ZM11.7441 11.4923C11.7441 10.6 12.0075 9.72776 12.5011 8.98582C12.9946 8.24389 13.6962 7.66563 14.5169 7.32415C15.3376 6.98268 16.2408 6.89333 17.1121 7.06742C17.9834 7.2415 18.7837 7.67119 19.4119 8.30215C20.04 8.93311 20.4678 9.737 20.6411 10.6122C20.8145 11.4873 20.7255 12.3945 20.3856 13.2189C20.0456 14.0433 19.4699 14.7479 18.7312 15.2436C17.9926 15.7394 17.1241 16.004 16.2358 16.004C15.0449 16.0026 13.9033 15.5268 13.0612 14.681C12.2192 13.8352 11.7455 12.6885 11.7441 11.4923Z"
      fill="currentColor"
    />
  </svg>
)

export default ProfileAnonIcon
