type ChannelNamesType = Record<string, string>

// All channels in the DB, except the ones marked as "old"
export const channelNames: ChannelNamesType = {
  'gartner-executive-programs-cio': 'Executive programs & CIO',
  'gartner-ciso-coalition': 'CISO Coalition',
  'gartner-enterprise-leaders': 'Enterprise Leaders',
  'gartner-gpi-mvps': 'MVPs',
  'gartner-rb-russia-invasion-ukraine': 'Research Board: Russian Invasion of Ukraine',
  'research-board': 'Research Board',
  'women-cio': 'Women CIOs',
  'us-state-and-local-government': 'US State and Local Government',
  'mse-cio-peer-community': 'MSE CIO Peer Community',
  'data-analytics-leaders': 'Data & Analytics Leaders',
  'it-infrastructure-and-operations-leaders': 'IT Infrastructure and Operations Leaders',
  'grb-regional-cios': 'GRB: Regional CIOs (Private)',
  'lead-community': 'Lead Community',
}
