import { PAGE_INDEX_COMMENTS_THRESHOLD } from '@/constants/gpc'
import { PostType, PublicPostType } from '@/types/api'
import { isPollType, isQuestion } from './post'
import { pluralize } from './strings'

export const buildMetaDescription = ({ text, num_responses, comments }: PublicPostType): string => {
  if (num_responses) {
    const pluralizedParticipants = pluralize(num_responses, 'participant')
    return `${text} View the poll results (${num_responses} ${pluralizedParticipants}) and benchmark your thoughts against verified peer responses.`
  }
  let commentsLength = 0
  if (!!comments) {
    commentsLength = comments.length
  }
  const pluralizedComment = pluralize(commentsLength, 'comment')
  return `${text} Read the answers (${commentsLength} ${pluralizedComment}) from verified experts and share your thoughts with Gartner Peer Community.`
}

export const isSeoPage = (post: PostType): boolean => {
  // we use that value to be sync with server num comments count
  const totalComments = post.num_comments // countCommentsAndReplies(post)
  if (isPollType(post.type)) {
    return !!(
      post.source !== 'pc-migrated' &&
      post.num_responses > 0 &&
      post.text.includes('?') &&
      totalComments > 0 &&
      post.options &&
      post.options[0] !== 'Yes'
    )
  }

  if (isQuestion(post.type)) {
    const hasOptions = !post.options || post.options.length < 1
    return !!(
      hasOptions &&
      post.source !== 'pc-migrated' &&
      totalComments >= PAGE_INDEX_COMMENTS_THRESHOLD &&
      post.text.includes('?')
    )
  }

  return false
}
