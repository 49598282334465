type CloseIconProps = {
  size?: number
}

const CloseIcon = ({ size = 15 }: CloseIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
    <title>close icon</title>
    <path
      fill="currentColor"
      d="M28.2 24 47.1 5.1c1.2-1.2 1.2-3.1 0-4.2C45.9-.3 44-.3 42.9.9L24 19.7 5.1.9C3.9-.3 2-.3.9.9-.3 2.1-.3 4 .9 5.1L19.8 24 .9 42.9c-1.2 1.2-1.2 3.1 0 4.2.6.6 1.3.9 2.1.9s1.5-.3 2.1-.9L24 28.2l18.9 18.9c.6.6 1.4.9 2.1.9s1.5-.3 2.1-.9c1.2-1.2 1.2-3.1 0-4.2L28.2 24z"
    />
  </svg>
)

export default CloseIcon
