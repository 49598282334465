import { createContext } from 'react'

type AppContextType = {
  isBot: boolean
}

const AppContext = createContext<AppContextType>({
  isBot: false,
})
AppContext.displayName = 'AppContext'

export default AppContext
