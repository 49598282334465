'use client'

import { AnonymousPublicProfile } from '@/constants/profile'
import { PublicProfileType } from '@/types/api'
import { createContext, Dispatch, SetStateAction } from 'react'

type ProfileContextType = {
  profile: PublicProfileType
  logout: () => void
  setProfile: Dispatch<SetStateAction<PublicProfileType>>
}

const ProfileContext = createContext<ProfileContextType>({
  profile: AnonymousPublicProfile,
  logout: () => {},
  setProfile: () => {},
})
ProfileContext.displayName = 'ProfileContext'

export default ProfileContext
